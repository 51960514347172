export default {
  name: 'TemplateTabContent',
  props: {
    dealListItem: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    filterConditions: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rowGroup: [],
      setNum: 12,
    };
  },
  mounted() {
    this.dealListItem.list.forEach((element, inx) => {
      if (inx % 3 === 0) this.rowGroup.push([]);
      this.rowGroup[Math.floor(inx / 3)].push(element);
    });
  },
  computed: {
    applyFilter() {
      const { filterConditions } = this;
      const jsonFilterCondition = JSON.parse(filterConditions);
      let result = this.dealListItem.list;

      // filter dealListItems
      if (jsonFilterCondition.length !== 0) {
        if (jsonFilterCondition.price) {
          const resultPrice = result.filter((item) => Number(item[1][0].totalAfterDiscount) >= jsonFilterCondition.price[0]
            && Number(item[1][0].totalAfterDiscount) <= jsonFilterCondition.price[1]);
          result = [...resultPrice];
        }

        const resultHotel = result.filter((item) => {
          const cond1 = (jsonFilterCondition['hotel-name'] && jsonFilterCondition['hotel-name'].length) ? jsonFilterCondition['hotel-name'].indexOf(item[1][0].hotelName) > -1 : true;
          const cond2 = (jsonFilterCondition['hotel-basis'] && jsonFilterCondition['hotel-basis'].length) ? jsonFilterCondition['hotel-basis'].indexOf(item[1][0].basisName) > -1 : true;
          const cond3 = (jsonFilterCondition['hotel-dest'] && jsonFilterCondition['hotel-dest'].length) ? jsonFilterCondition['hotel-dest'].indexOf(item[1][0].destCode) > -1 : true;
          return cond1 && cond2 && cond3;
        });
        result = [...resultHotel];

        let sorted = [];
        if (jsonFilterCondition.sort) {
          sorted = result.slice().sort((firstItem, secondItem) => {
            if (firstItem[1][0].totalAfterDiscount < secondItem[1][0].totalAfterDiscount) return 1;
            if (firstItem[1][0].totalAfterDiscount > secondItem[1][0].totalAfterDiscount) return -1;
            return 0;
          });
          result = [...sorted];
        } else {
          sorted = result.slice().sort((firstItem, secondItem) => {
            if (firstItem[1][0].totalAfterDiscount < secondItem[1][0].totalAfterDiscount) return -1;
            if (firstItem[1][0].totalAfterDiscount > secondItem[1][0].totalAfterDiscount) return 1;
            return 0;
          });
          result = [...sorted];
        }
      }

      // sort for atlantis remark
      const atlantisDeals = result.filter((item) => this.hasAtlantisRemark(item[1][0]));
      const nonAtlantisDeals = result.filter((item) => !this.hasAtlantisRemark(item[1][0]));
      result = [...atlantisDeals.sort((a, b) => a[1][0].totalAfterDiscount - b[1][0].totalAfterDiscount), ...nonAtlantisDeals];

      // set limitation to show
      if (result.length > this.setNum) {
        result = result.slice(0, this.setNum);
      }

      // grouping for display
      const rowGroup = [];
      result.forEach((element, inx) => {
        if (inx % 3 === 0) rowGroup.push([]);
        rowGroup[Math.floor(inx / 3)].push(element);
      });

      return rowGroup;
    },
  },
  methods: {
    loadMore() {
      this.setNum += 12;
    },
    hasAtlantisRemark(item) {
      const { extendedProperties, roomRemark } = item;
      return (extendedProperties.sourceName === 'ATLANTIS') && (roomRemark !== null && roomRemark.trim() !== '');
    },
  },
};
