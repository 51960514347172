<template>
  <div class="tab-pane" :id="`tabs-1.${index}`" role="tabpanel">
    <div class="lesiurpackages_box organized tours" v-if="applyFilter.length > 0">
      <div class="row" v-for="(rowItem, rowIndex) in applyFilter" :key="rowIndex">
        <div class="col-lg-8" v-if="rowIndex % 2 === 0">
          <div class="row">
            <LesiurtabItem :items="rowItem[0]" :isBig="!!rowItem[1]" />
          </div>
        </div>

        <div :class="rowItem.length < 3 ? 'col-lg-8' : 'col-lg-4'" v-else>
          <div class="row">
            <LesiurtabItem :items="rowItem[0]" />
            <LesiurtabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
          </div>
        </div>

        <div class="col-lg-4" v-if="rowIndex % 2 === 0">
          <div class="row">
            <LesiurtabItem :items="rowItem[1]" v-if="!!rowItem[1]" />
            <LesiurtabItem :items="rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>

        <div class="col-lg-8" v-else>
          <div class="row">
            <LesiurtabItem :items="rowItem[2]" :isBig="!!rowItem[2]" v-if="!!rowItem[2]" />
          </div>
        </div>

      </div>
    </div>
    <div class="d-flex loadMore">
      <button style="height: 40px" @click="loadMore" v-if="this.dealListItem.list.length > this.setNum">{{
        $t('home.show-more') }}</button>
    </div>
  </div>
</template>
<script>
import TemplateLesiurTabContent from './TemplateLesiurtabContent';

export default {
  name: 'tabContentTheme0',
  mixins: [TemplateLesiurTabContent],
  components: {
    LesiurtabItem: () => import('@/components/home/lesiurtabItem/LesiurtabItemTheme2'),
  },
};
</script>
<style scoped>
.loadMore {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.loadMore>button {
  padding: 10px 24px;
  border-radius: 50px;
  border: 1px solid rgb(0, 97, 171);
  border: 1px solid rgba(var(--theme-primary));
  cursor: pointer;
}
</style>
